import React from "react";
import { SEO, Image, MarkdownContent, DynamicForm } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class CareersPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      myqsqrhtzosxyzyxxmwzxeorhbfqyxqperlk,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.ariasalons.net/careers/"}
        />
        <Grid
          className={"custom-page-careers custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"moykmjcrrrnriebk full-width"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"upfolwyyfnhstkre"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    maxHeight: "400px",
                    minHeight: "400px",
                    objectFit: "cover",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "cd9eab68-b1ef-48fc-bb30-abbed93dab22",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      background: "rgba(0,0,0,0.62)",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "27dfb7b4-44d7-4d31-bdad-7ae77beddd5c",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ebvnghdwajkbosdf"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"qdsumcovvxmftohg"}
              style={{ padding: 16, boxShadow: "0 0 0 2px #000000" }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "5a8412f0-d789-4790-b4b0-b735f14700de",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"xecoxzaedowlndoi"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"ddxgyzyavenhvywa"}
              style={{ padding: 16 }}
              width={8}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "6a6eeb90-1770-4940-90bb-450052409cff",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"ibwycnvyrijlfsuc"}
              style={{ padding: 16 }}
              width={7}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "d8ec52bd-2952-4b73-94fe-a13edb578198",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"muheqetxunqciydr"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"uhmqmdqygmnhswto"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"jhmxalrylxjeeowx"}
              style={{ padding: 16, boxShadow: "0 0 0 2px #000000" }}
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={2186}
                  businessInfoId={7334}
                  websiteId={8682}
                  locationId={2306}
                  componentConfigurationId={97425}
                  header={myqsqrhtzosxyzyxxmwzxeorhbfqyxqperlk.data.header}
                  fields={
                    myqsqrhtzosxyzyxxmwzxeorhbfqyxqperlk.data.dynamicFormFields
                  }
                  consentSettings={
                    myqsqrhtzosxyzyxxmwzxeorhbfqyxqperlk.data.consentSettings
                  }
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"ulmuzuheeztnipek"}
              style={{ padding: 16 }}
              width={8}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", objectFit: "contain" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "6cef5988-d707-4f85-af42-ddf3439ec055",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ceoumrtmmmruduej"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"apfalaafjbcotpuj"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 28992 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
    myqsqrhtzosxyzyxxmwzxeorhbfqyxqperlk: fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "e03c08a0-bb1e-471f-9370-0063d9d00e47" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
          sendEmailToSelectedLocation
        }
        consentSettings {
          show
          required
        }
      }
    }
  }
`;
